<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "เพิ่มอีเวนต์การขาย",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, DatePicker, Multiselect },
  data() {
    return {
      // tableData: tableData,

      title: "เพิ่มอีเวนต์การขาย",
      items: [
        {
          text: "หน้าหลัก",
        },
        {
          text: "เพิ่มอีเวนต์การขาย",
          active: true,
        },
      ],
      overlayFlag: false,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,

      // selectMode: "single",
      userBranchId: "",
      branchId: "",
      //   payMethCode: "",
      eventCode: "",
      manageBranch: "",
      shortNameEn: "",
      shortNameTh: "",
      nameTh: "",

      nameEn: "",
      payMethod: "",
      submitform: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",

      userBranch: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,

      eventDate: "",
      regPlace: "",
      regPlaceOptions: [
        {
          regId: "ShowRoom",
        },
        {
          regId: "Road Show",
        },
      ],
      cusSrcId: "",
      cusSrcOptions: [],
    };
  },
  validations: {
    eventCode: {},
    nameTh: {
      required,
    },
    nameEn: {},
    manageBranch: {},
    shortNameTh: {},
    shortNameEn: {},
    cusSrcId: {},
    regPlace: {},
    eventDate: {},
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.branchMaster = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
  },
  created() {
    this.getDataCusSrc();
  },
  methods: {
    /**
     * Search the table data with search input
     */

    getDataCusSrc: function() {
      this.overlayFlag = true;
    //   console.log('Success ');
      useNetw
        .get("api/d-customer-source", {					
          params: {
              
            page: 1,
            perPage: 100,

          },
        }) // ?เอามาจากไหน

        .then((response) => {
            
          this.cusSrcOptions = response.data.data
        })
        .catch((err) => {
          //?ถ้าดึงข้อมูลไม่ได้ให้ทำอะไรไม่ได้ให้ทำอะไรฃ
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              appConfig.swal.type.error
            );
            this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },

    tooltipForm() {
      // console.log(this.$v)
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitDfi();
      }
    },
    submitDfi: function() {
      this.overlayFlag = true; //skeleton true
      useNetw
        .post("api/d-event/store", {
          eventCode: this.eventCode,
          nameTh: this.nameTh,
          nameEn: this.nameEn,
          shortNameTh: this.shortNameTh,
          shortNameEn: this.shortNameEn,
          manageBranch: this.manageBranch,
          eventDate: this.eventDate,
          regPlace: this.regPlace.regId,
          cusSrcId: this.cusSrcId.cusSrcId
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "d-event/edit",
            params: {
              eventId: response.data.eventId,
            },
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-overlay
      :show="overlayFlag"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        รหัสอีเวนต์การขาย <br />
                        <input
                          type="text"
                          class="form-control"
                          placeholder="รหัสอีเวนต์การขาย"
                          v-model="eventCode"
                          :class="{
                            'is-invalid': submitform && $v.eventCode.$error,
                          }"
                        />
                        <div
                          v-if="submitform && $v.eventCode.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.eventCode.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3 position-relative">
                        <code> * </code>ชื่อ (ไทย):
                        <b-form-input
                          v-model="nameTh"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameTh.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="mb-3 position-relative">
                        ชื่อ (อังกฤษ):
                        <b-form-input
                          v-model="nameEn"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameEn.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameEn.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameEn.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        ชื่อย่อ (ไทย):
                        <b-form-input
                          v-model="shortNameTh"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.shortNameTh.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.shortNameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.shortNameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        ชื่อย่อ (อังกฤษ):
                        <b-form-input
                          v-model="shortNameEn"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.shortNameEn.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.shortNameEn.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.shortNameEn.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-1"></div>

                    <div class="col-3">
                      <div class="mb-3 position-relative">
                        <label for="">&nbsp;</label><br />
                        <b-form-checkbox
                          v-model="manageBranch"
                          value="1"
                          unchecked-value="0"
                          class="mb-3"
                          checked
                          plain
                          :class="{
                            'is-invalid': submitform && $v.manageBranch.$error,
                          }"
                          >ส่งข้อมูลไปทุกสาขา</b-form-checkbox
                        >
                        <div
                          v-if="submitform && $v.manageBranch.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.manageBranch.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="mb-3 position-relative">
                        <label for="">วันที่เริ่มงาน :</label>

                        <date-picker
                          v-model="eventDate"
                          :first-day-of-week="1"
                          format="YYYY-MM-DD"
                          value-type="format"
                          lang="en"
                          :class="{
                            'is-invalid': submitform && $v.eventDate.$error,
                          }"
                        >
                        </date-picker>
                        <div
                          v-if="submitform && $v.eventDate.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.eventDate.required">{{ error }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-sm-9">
                      <div class="mb-3 position-relative">
                        <label for="nameTh">ประเภทอีเวนต์</label>:
                        <multiselect
                          v-model="regPlace"
                          :options="regPlaceOptions"
                          label="regId"
                          track-by="nameTh"
                          :show-labels="false"
                          placeholder="ประเภทอีเวนต์"
                          :class="{
                            'is-invalid': submitform && $v.regPlace.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.regPlace.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.regPlace.required">{{ error }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 col-sm-9">
                      <div class="mb-3 position-relative">
                        <label for="nameTh">ช่องทาง</label>:
                        <multiselect
                          v-model="cusSrcId"
                          :options="cusSrcOptions"
                          label="nameTh"
                          track-by="nameTh"
                          @search-change="getDataCusSrc()"
                          :show-labels="false"
                          placeholder="ประเภทอีเวนต์"
                          :class="{
                            'is-invalid': submitform && $v.cusSrcId.$error,
                          }"
                        >
                        </multiselect>
                        <div
                          v-if="submitform && $v.cusSrcId.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.cusSrcId.required">{{ error }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-12 col-md-12">
                        <b-button
                          class="btn float-end m-1"
                          variant="success"
                          @click="tooltipForm"
                        >
                          บันทึก
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>
