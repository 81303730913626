var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('b-overlay',{attrs:{"show":_vm.overlayFlag,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" รหัสอีเวนต์การขาย "),_c('br'),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.eventCode),expression:"eventCode"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.eventCode.$error,
                        },attrs:{"type":"text","placeholder":"รหัสอีเวนต์การขาย"},domProps:{"value":(_vm.eventCode)},on:{"input":function($event){if($event.target.composing)return;_vm.eventCode=$event.target.value}}}),(_vm.submitform && _vm.$v.eventCode.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.eventCode.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('code',[_vm._v(" * ")]),_vm._v("ชื่อ (ไทย): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.nameTh.$error,
                        },model:{value:(_vm.nameTh),callback:function ($$v) {_vm.nameTh=$$v},expression:"nameTh"}}),(_vm.submitform && _vm.$v.nameTh.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.nameTh.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-4"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ชื่อ (อังกฤษ): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.nameEn.$error,
                        },model:{value:(_vm.nameEn),callback:function ($$v) {_vm.nameEn=$$v},expression:"nameEn"}}),(_vm.submitform && _vm.$v.nameEn.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.nameEn.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ชื่อย่อ (ไทย): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.shortNameTh.$error,
                        },model:{value:(_vm.shortNameTh),callback:function ($$v) {_vm.shortNameTh=$$v},expression:"shortNameTh"}}),(_vm.submitform && _vm.$v.shortNameTh.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.shortNameTh.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_vm._v(" ชื่อย่อ (อังกฤษ): "),_c('b-form-input',{staticClass:"form-control",class:{
                          'is-invalid': _vm.submitform && _vm.$v.shortNameEn.$error,
                        },model:{value:(_vm.shortNameEn),callback:function ($$v) {_vm.shortNameEn=$$v},expression:"shortNameEn"}}),(_vm.submitform && _vm.$v.shortNameEn.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.shortNameEn.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-1"}),_c('div',{staticClass:"col-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":""}},[_vm._v(" ")]),_c('br'),_c('b-form-checkbox',{staticClass:"mb-3",class:{
                          'is-invalid': _vm.submitform && _vm.$v.manageBranch.$error,
                        },attrs:{"value":"1","unchecked-value":"0","checked":"","plain":""},model:{value:(_vm.manageBranch),callback:function ($$v) {_vm.manageBranch=$$v},expression:"manageBranch"}},[_vm._v("ส่งข้อมูลไปทุกสาขา")]),(_vm.submitform && _vm.$v.manageBranch.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.manageBranch.required)?_c('span',[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":""}},[_vm._v("วันที่เริ่มงาน :")]),_c('date-picker',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.eventDate.$error,
                        },attrs:{"first-day-of-week":1,"format":"YYYY-MM-DD","value-type":"format","lang":"en"},model:{value:(_vm.eventDate),callback:function ($$v) {_vm.eventDate=$$v},expression:"eventDate"}}),(_vm.submitform && _vm.$v.eventDate.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.eventDate.required)?_c('span',[_vm._v(_vm._s(_vm.error))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4 col-sm-9"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"nameTh"}},[_vm._v("ประเภทอีเวนต์")]),_vm._v(": "),_c('multiselect',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.regPlace.$error,
                        },attrs:{"options":_vm.regPlaceOptions,"label":"regId","track-by":"nameTh","show-labels":false,"placeholder":"ประเภทอีเวนต์"},model:{value:(_vm.regPlace),callback:function ($$v) {_vm.regPlace=$$v},expression:"regPlace"}}),(_vm.submitform && _vm.$v.regPlace.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.regPlace.required)?_c('span',[_vm._v(_vm._s(_vm.error))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"col-md-4 col-sm-9"},[_c('div',{staticClass:"mb-3 position-relative"},[_c('label',{attrs:{"for":"nameTh"}},[_vm._v("ช่องทาง")]),_vm._v(": "),_c('multiselect',{class:{
                          'is-invalid': _vm.submitform && _vm.$v.cusSrcId.$error,
                        },attrs:{"options":_vm.cusSrcOptions,"label":"nameTh","track-by":"nameTh","show-labels":false,"placeholder":"ประเภทอีเวนต์"},on:{"search-change":function($event){return _vm.getDataCusSrc()}},model:{value:(_vm.cusSrcId),callback:function ($$v) {_vm.cusSrcId=$$v},expression:"cusSrcId"}}),(_vm.submitform && _vm.$v.cusSrcId.$error)?_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.cusSrcId.required)?_c('span',[_vm._v(_vm._s(_vm.error))]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-sm-12 col-md-12"},[_c('b-button',{staticClass:"btn float-end m-1",attrs:{"variant":"success"},on:{"click":_vm.tooltipForm}},[_vm._v(" บันทึก ")])],1)])])])])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }